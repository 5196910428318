.staging {
  font-family: "Roboto", sans-serif;
  height: 100vh;
  width: 100vw;
  margin: 0px;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: #a36e29;

  .card {
    height: 80vh;
    width: 80vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .anim {
      width: 30vw;
      margin-bottom: 50px;
    }

    .main-text {
      text-align: center;
      font-size: 1.2rem;
      font-weight: 600;
      margin-top: 50px;
      margin-bottom: 20px;
    }

    .login-action {
      margin-bottom: 20px;
      font-size: 1rem;
      font-weight: 600;
      color: #a36e29;
      text-decoration: underline;
      cursor: pointer;
    }

    .query-txt {
      font-size: 1rem;

      .mail-txt {
        color: #a36e29;
        font-weight: 600;
        cursor: pointer;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
