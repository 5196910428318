.registration-bank-details {
    width: 100%;
  
    .step-text {
      color: gray;
    }
  
    .heading {
      font-size: 2rem;
      font-weight: 600;
      margin-bottom: 50px;
    }
  
    .divider {
      width: 100%;
      height: 2px;
      background-color: rgb(183, 183, 183);
      margin-top: 50px;
      margin-right: 510px;
    }
  
    .actions {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
  
      .btn-primary {
        text-transform: none;
        font-family: "Roboto", sans-serif;
        padding: 10px 30px;
        color: white;
        background-color: #a36e29;
      }
  
      .btn-secondary {
        text-transform: none;
        font-family: "Roboto", sans-serif;
        padding: 10px 30px;
        color: #a36e29;
        border: 1.5px solid #a36e29;
      }
    }
  }