.landing {
  font-family: "Roboto", sans-serif;

  .appbar {
    background-color: #a36e29;
    overflow: hidden;

    .toolbar {
      position: relative;
      margin: auto;
      height: 100px;
      width: 80vw;

      .logo {
        height: 40px;
        margin-right: 0.5rem;
        vertical-align: middle;
        cursor: pointer;
      }

      .btns {
        margin-left: auto;

        .link {
          margin-left: 20px;
          vertical-align: middle;
          cursor: pointer;

          .btn {
            font-family: "Roboto", sans-serif;
            color: white;
            text-transform: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        .link-primary {
          margin-left: 20px;
          vertical-align: middle;
          cursor: pointer;
          padding: 10px 20px;
          background-color: white;
          border-radius: 5px;

          .btn {
            font-family: "Roboto", sans-serif;
            color: #a36e29;
            text-transform: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .content {
    height: calc(100vh - 160px);

    .backdrop {
      position: relative;
      z-index: 0;

      .top {
        height: 30vh;
        background-color: #a36e29;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .hero-text {
          width: 75vw;
          margin: auto;
          padding-bottom: 50px;
          color: white;
          font-size: 3rem;
          font-weight: 800;
          line-height: 4rem;
        }
      }

      .bottom {
        height: calc(70vh - 160px);

        display: flex;
        align-items: center;
        flex-direction: column;

        .buttons {
          height: 120px;
          width: 75vw;

          display: flex;
          align-items: center;

          .btn-primary {
            width: 200px;
            height: 50px;
            background-color: #a36e29;
            margin-right: 50px;

            .btn-text {
              font-family: "Roboto", sans-serif;
              color: white;
              margin-right: 10px;
              text-transform: none;
              font-size: 1.1rem;
            }

            .icon {
              color: white;
              margin-left: 10px;
            }
          }

          .btn-secondary {
            width: 200px;
            height: 50px;
            border: 2px solid #a36e29;

            .btn-text {
              font-family: "Roboto", sans-serif;
              color: #a36e29;
              margin-right: 10px;
              text-transform: none;
              font-size: 1.1rem;
            }

            .icon {
              color: #a36e29;
              margin-left: 10px;
            }
          }
        }

        .comments {
          height: calc(70vh - 280px);
          width: 75vw;
          color: white;

          display: flex;
          justify-content: flex-end;
          align-items: center;
          flex-direction: column;

          .comment-item {
            display: flex;

            .comment-text {
              width: 60vw;
              text-align: center;
              margin-left: 20px;
              margin-right: 20px;

              font-size: 1.05rem;
              font-style: italic;
              margin-top: 10px;
              font-weight: 500;
            }
          }

          .comment-author {
            margin-top: 15px;
            font-size: 1rem;
            font-weight: 500;
          }

          .comment-author-title {
            font-size: 0.8rem;
            margin-top: 5px;
            margin-bottom: 40px;
          }
        }
      }
    }

    .hero {
      position: absolute;
      top: 0;
      right: 0;
      height: 25vw;
      z-index: 1;
      margin-right: 10vw;
      margin-top: 6vw;
    }
  }

  .footer {
    height: 60px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .seperator {
      height: 2px;
      width: 75vw;
      background-color: rgb(195, 195, 195);
    }

    .items-row {
      height: 58px;
      width: 75vw;

      display: flex;
      justify-content: space-between;
      align-items: center;

      .company {
        font-size: 1.05rem;
        font-weight: 500;
      }

      .actions {
        display: flex;

        .action-item {
          margin-left: 20px;
          font-weight: 300;
          cursor: pointer;
        }
      }
    }
  }
}
