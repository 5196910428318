.drawer-component {
    font-family: "Roboto", sans-serif;
    width: 400px;
    max-height: 100vh;
    
  
    .data {
      height: 100%;
  
      .logo {
        width: 100%;
        height: 45px;
        margin-top: 30px;
        margin-bottom: 30px;
        object-fit: contain;
      }
  
      .drawer-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
  
        .profile {
          border-radius: 100%;
          height: 100px;
          width: 100px;
          margin-top: 40px;
          margin-bottom: 10px;
          object-fit: cover;
        }
  
        .name {
          color: #a36e29;
          font-weight: 800;
          margin-top: 10px;
          margin-bottom: 10px;
          font-size: 1.1rem;
        }
  
        .edit-option {
          color: #a36e29;
          margin-top: 10px;
          margin-bottom: 30px;
          padding: 5px 16px;
          border: 1px rgb(218, 218, 218) solid;
          border-radius: 50px;
          font-size: 0.8rem;
          cursor: pointer;
  
          &:hover {
            background-color: #f9f5ec;
            font-weight: 600;
          }
        }
        .sub-categories {
          margin-top: 10px;
          padding-left: 30px; // Adjust the indentation based on your design
          border-left: 2px solid #a36e29; // Adjust the border color based on your design
      
          .drawer-item {
            width: 100%;
            padding-left: 10px; // Adjust the padding based on your design
      
            .icon {
              padding-left: 10px; // Adjust the padding based on your design
              padding-right: 10px;
            }
      
            .title {
              font-size: 1rem; // Adjust the font size based on your design
            }
          }
        }
      }
    }
  }