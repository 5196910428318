.drawer-item-selected {
    color: #a36e29;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: left;
    align-items: center;
    cursor: pointer;
    background-color: #f9f5ec;
    padding-left: 20px;
  
    .icon {
      padding-left: 20px;
      padding-right: 20px;
    }
  
    .title {
      font-size: 1.1rem;
    }
  }
  
  .drawer-item {
    width: 75%;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: left;
    align-items: center;
    border-radius: 20px;
    cursor: pointer;
    padding-left: 20px;
  
    .icon {
      padding-left: 20px;
      padding-right: 20px;
    }
  
    .title {
      font-size: 1.1rem;
    }
  }