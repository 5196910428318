.home-component {
  font-family: "Roboto", sans-serif;
  display: flex;

  .divider {
    width: 1px;
    height: 100%;
  }

  .component {
    width: 100%;
    height: 100vh;
  }
}