.Products {
  background-color: rgb(249, 249, 249);
  min-height: 100%;
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .head-txt {
      padding: 30px 50px;
      padding-right: 0px;
      font-size: 1.9rem;
      font-weight: 500;
      display: inline-block;
    }

    .head-refresh {
      padding-top: 45px;
      color: #a36e29;
      padding-left: 5px;
      font-size: 17px;
      cursor: pointer;
    }

    .button {
      margin-right: 2vw;
      width: 200px;
      height: 50px;
      background-color: #a36e29;
      color: white;
      text-transform: none;
      font-family: "Roboto", sans-serif;
      font-size: 1rem;

      &:hover {
        background-color: white;
        color: #a36e29;
        border: 1px solid #a36e29;
      }
    }
  }
  .table-paper {
    height: 70%;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 25px;
    border-radius: 10px;
    box-shadow: 0 0 10px 1px rgb(241, 241, 241);
    padding-left: 10px;

    .name-content {
      display: flex;
      align-items: center;

      .company-img {
        height: 60px;
        width: 60px;
        border-radius: 100%;
        margin-right: 10px;
      }
    }

    .actions-content {
      align-items: start;

      .allow {
        color: #a36e29;
        border: 1px solid #a36e29;
        border-radius: 3px;
        background-color: #f9f5ec;
        width: 25px;
        height: 25px;
        padding: 3px;
        margin-left: 5px;
        margin-right: 5px;
        cursor: pointer;
      }

      .block {
        color: red;
        border: 1px solid red;
        border-radius: 3px;
        background-color: rgb(250, 226, 226);
        width: 25px;
        height: 25px;
        padding: 3px;
        margin-left: 5px;
        margin-right: 5px;
        cursor: pointer;
      }
    }
  }
}

.button {
  text-transform: none !important;
  border-radius: 8px !important;
  padding: 8px 16px !important;
  font-weight: 500 !important;
  
  &:hover {
    opacity: 0.9;
  }
}

// Add specific styles for contained (filled) button
.button[variant='contained'] {
  background-color: #1976d2 !important;
  color: white !important;
  
  &:hover {
    background-color: #1565c0 !important;
  }
}

// Add specific styles for outlined button
.button[variant='outlined'] {
  border: 1px solid #1976d2 !important;
  color: #1976d2 !important;
  
  &:hover {
    background-color: rgba(25, 118, 210, 0.04) !important;
  }
}
