.input-text-field {
    margin-right: 50px;

    .label {
        font-weight: 600;
        margin-bottom: 10px;
    }

    .field {
        width: 100%;
        margin-bottom: 20px;
    }
}